import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const index: RouteConfig = {
  path: config.CONTACT_ROUTE.path,
  redirect: config.CONTACT_ROUTE.redirect,
  name: config.CONTACT_ROUTE.name,
  meta: config.CONTACT_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.LIST_CONTACT_ROUTE.path,
      name: config.LIST_CONTACT_ROUTE.name,
      meta: config.LIST_CONTACT_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ListContact.vue"),
    },
    {
      path: config.CREATE_CONTACT_ROUTE.path,
      name: config.CREATE_CONTACT_ROUTE.name,
      meta: config.CREATE_CONTACT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreateContact.vue"),
    },
    {
      path: config.EDIT_CONTACT_ROUTE.path,
      name: config.EDIT_CONTACT_ROUTE.name,
      meta: config.EDIT_CONTACT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreateContact.vue"),
    },
  ],
};

export default index;
