// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const CONTACT_ROUTE: RoutePathModel = {
  path: "/contact",
  url: "/contact",
  name: "lbl_contact",
  redirect: "/contact/read",
  meta: {
    breadcrumb: "lbl_contact",
    title_head: "lbl_contact",
    need_auth: true
  }
}

export const LIST_CONTACT_ROUTE: RoutePathModel = {
  path: "read",
  url: `${CONTACT_ROUTE.url}/read`,
  name: "lbl_list_contact",
  meta: {
    breadcrumb:[
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
          name:'lbl_contact',
          href:`${CONTACT_ROUTE.url}/read`
      },
    ],
    title_head: "lbl_list_contact"
  }
}

export const CREATE_CONTACT_ROUTE: RoutePathModel = {
  path: "create",
  url: `${CONTACT_ROUTE.url}/create`,
  name: "lbl_create_contact_data",
  meta: {
    breadcrumb:[
      {
          name:'lbl_contact',
          icon:'idcard',
          href:`${CONTACT_ROUTE.url}/read`
      },
      {
        name:'lbl_create_contact_data',
        href:`${CONTACT_ROUTE.url}/create`
    },
    ],
    title_head: "lbl_create_contact_data",
    mode: Mode.CREATE
  }
}

export const EDIT_CONTACT_ROUTE: RoutePathModel = {
  path: `${CONTACT_ROUTE.url}/update/:id`,
  url: `${CONTACT_ROUTE.url}/update/:id`,
  name: "lbl_edit_contact_data",
  meta: {
    breadcrumb:[
      {
          name:'lbl_contact',
          icon:'idcard',
          href:`${CONTACT_ROUTE.url}/read`
      },
      {
        name:'lbl_edit_contact_data',
        href:`${CONTACT_ROUTE.url}/update`
    },
    ],
    title_head: "lbl_edit_contact_data",
    mode: Mode.EDIT,
  }
}