var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      on: { change: _vm.onChange },
      model: {
        value: _vm.lang,
        callback: function($$v) {
          _vm.lang = $$v
        },
        expression: "lang"
      }
    },
    _vm._l(_vm.languageOptions, function(option) {
      return _c(
        "a-select-option",
        { key: option.key, attrs: { value: option.value } },
        [
          _c("a-tooltip", [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t(option.label)) + " ")
            ]),
            _vm._v(" " + _vm._s(_vm.$t(option.label)) + " ")
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }