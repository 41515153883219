import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { initWoDetailDraft } from "@/store/resources/WorkOrder.resource";
import {
  FormValue,
  WoDetailDraft,
  WoSparepartRow,
  WoUnitRow,
} from "@/store/workOrder.store";
import {
  WorkOrderCreateRequestDto,
  WorkOrderDraftResponseDto,
  WorkOrderResponseDto,
  WorkOrderSparePartRequestDto,
  WorkOrderUnitRequestDto,
} from "@interface/work-order";
import moment, { Moment } from "moment";
import useDate from "./useDate";

const useWorkOrder = () => {
  const findAllWoHeader = (params: RequestQueryParamsModel) => {
    return logisticServices.getAllWorkOrderHeader(params);
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return logisticServices.getAllWorkOrder(params);
  };

  const create = (body: WorkOrderCreateRequestDto) => {
    return logisticServices.createWorkOrder(body);
  };

  const findById = (secureId: string): Promise<WorkOrderResponseDto> => {
    return logisticServices.getDetailWorkOrder(secureId);
  };

  const findByIdDraft = (
    secureId: string
  ): Promise<WorkOrderDraftResponseDto> => {
    return logisticServices.getDetailDrafWo(secureId);
  };

  const printCsf = (secureId: string): Promise<Blob> => {
    return logisticServices.printCsfFromWO(secureId);
  };

  const initCreateWoSparePartDto = (): WorkOrderSparePartRequestDto => ({
    notes: "",
    partNumber: "",
    productId: "",
    productLocationId: "",
    productUomId: "",
    qtyWorkOrder: 0,
    unitCode: "",
  });

  const initCreateWoUnitDto = (): WorkOrderUnitRequestDto => ({
    assetId: "",
    unitCode: "",
    unitSpecification: "",
  });

  const initCreateDto = (): WorkOrderCreateRequestDto => ({
    branchWarehouseId: "",
    customerAddress: "",
    customerId: "",
    description: "",
    mechanicId: "",
    picName: "",
    scheduleOrder: "",
    spareParts: [],
    units: [],
    workOrderDate: "",
  });

  const mapFormWoSparepartToCreateDto = (
    row: WoSparepartRow
  ): WorkOrderSparePartRequestDto => {
    const req: WorkOrderSparePartRequestDto = initCreateWoSparePartDto();

    req.notes = row.notes;
    req.partNumber = row.partNumber;
    req.productId = row.productId;
    req.productLocationId = row.productLocationId;
    req.productUomId = row.productUomId;
    req.qtyWorkOrder = row.qtyWorkOrder;
    req.unitCode = row.unitCode;

    return req;
  };

  const mapFormWoUnitToCreateDto = (
    row: WoUnitRow
  ): WorkOrderUnitRequestDto => {
    const req: WorkOrderUnitRequestDto = initCreateWoUnitDto();

    req.assetId = row.assetId;
    req.unitCode = row.unitCode;
    req.unitSpecification = row.unitSpecification;

    return req;
  };

  const mapFormToCreateDto = (form: FormValue): WorkOrderCreateRequestDto => {
    const req: WorkOrderCreateRequestDto = initCreateDto();

    req.branchWarehouseId = form.branchWarehouseId;
    req.customerAddress = form.customerAddress;
    req.customerId = form.customer?.key ?? "";
    req.description = form.description;
    req.mechanicId = form.mechanicId;
    req.picName = form.picName;
    req.scheduleOrder = form.scheduleOrder?.format() ?? "";
    req.spareParts = form.spareParts.map(mapFormWoSparepartToCreateDto);
    req.units = form.units.map(mapFormWoUnitToCreateDto);
    req.workOrderDate = form.workOrderDate?.format() ?? "";

    return req;
  };

  /**
   * @description
   * map detail draft dto to detail page form
   */
  const mapDetailDraftDtoToDetail = (
    dto: WorkOrderDraftResponseDto
  ): WoDetailDraft => {
    const detail: WoDetailDraft = initWoDetailDraft();

    detail.branchWarehouse = dto.branchWarehouse;
    detail.branchWarehouseId = dto.branchWarehouseId;
    detail.customerAddress = dto.customerAddress;
    detail.customerId = dto.customerId;
    detail.customerName = dto.customerName;
    detail.description = dto.description;
    detail.documentNumber = dto.documentNumber;
    detail.mechanicId = dto.mechanicId;
    detail.mechanicName = dto.mechanicName;
    detail.picName = dto.picName;
    detail.scheduleOrder = dto.scheduleOrder ? moment(dto.scheduleOrder) : null;
    detail.spareParts = dto.spareParts;
    detail.units = dto.units;
    detail.workOrderDate = dto.workOrderDate ? moment(dto.workOrderDate) : null;
    detail.workOrderId = dto.workOrderId;

    return detail;
  };

  const toOption = (
    data: WorkOrderResponseDto[]
  ): Option<WorkOrderResponseDto>[] => {
    return data.map(item => ({
      key: item.id,
      label: item.documentNumber,
      value: item.id,
      meta: item,
    }));
  };

  const findBy = (field: Partial<{ documentNumber: string }>): string[] => {
    const query: string[] = [];

    if (field.documentNumber) {
      query.push(
        new SearchBuilder()
          .push(["documentNumber", field.documentNumber], { like: "both" })
          .build()
      );
    }

    return query;
  };

  const findByWoList = (
    params: Partial<{
      assetId: string;
      workOrderDate: null | Moment[];
      workOrderId: string;
      customerId: string;
      branchId: string;
      productCategoryId: string;
    }>
  ): string[] => {
    const { toStartDay, toEndDay } = useDate();
    const queries: string[] = [];

    Object.entries(params).forEach(([field, value]) => {
      if (field !== "workOrderDate" && value) {
        queries.push(
          new SearchBuilder().push([field, value as string]).build()
        );
      }
    });

    if (params.workOrderDate && params.workOrderDate.length) {
      const [start, end] = params.workOrderDate;

      queries.push(
        new SearchBuilder()
          .push(["workOrderDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["workOrderDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    return queries;
  };

  const printList = (params: RequestQueryParamsModel) => {
    return logisticServices.printWorkOrder(params);
  };

  const downloadList = (params: RequestQueryParamsModel) => {
    return logisticServices.downloadWorkOrder(params);
  };

  return {
    create,
    findById,
    findByIdDraft,
    initCreateDto,
    initCreateWoSparePartDto,
    initCreateWoUnitDto,
    mapDetailDraftDtoToDetail,
    mapFormToCreateDto,
    mapFormWoSparepartToCreateDto,
    mapFormWoUnitToCreateDto,
    printCsf,
    findAllWoHeader,
    toOption,
    findBy,
    findAll,
    findByWoList,
    printList,
    downloadList,
  };
};

export default useWorkOrder;
