import { StorageKeys } from "@/models/enums/storages.enum";
import { DecodedToken } from "@/models/interfaces/common.interface";
import jwtDecode from "jwt-decode";

const useStorage = () => {
  const token = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  const getPrivileges = () => {
    if (token) {
      const privileges: DecodedToken = jwtDecode(token);
      return privileges?.authorities ?? [];
    }
    return [];
  };
  return { getPrivileges };
};

export default useStorage;
