import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import { assetsServices } from "@/services/assets.service";
import {
  initFormValue,
  initStockAdjRow,
} from "@/store/resources/StockAdj.resource";
import { FormValue, StockAdjRow } from "@/store/stockAdj.store";
import { formatterNumber } from "@/validator/globalvalidator";
import { stockAdjustmentServices } from "@/views/logistic/stock-adjustment/services";
import {
  StockAdjustmentLineRequestCreateDTO,
  StockAdjustmentLineResponseDTO,
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "@/views/logistic/stock-adjustment/types";
import Decimal from "decimal.js-light";
import moment from "moment";

export const useCountQtyDifference = (row: StockAdjRow): string => {
  const physicalQty = row.physicalQty ?? 0;
  const qtyTotal = row.qty ?? 0;
  let diff = new Decimal(qtyTotal).minus(physicalQty).times(-1);

  if (qtyTotal < 0 && physicalQty < 0) {
    diff = new Decimal(qtyTotal).add(physicalQty);
  }

  return diff.isPositive()
    ? `+${formatterNumber(diff.toNumber())}`
    : formatterNumber(diff.toNumber());
};

export const useStockAdjustment = () => {
  const uploadAttachment = (file: File) => {
    const module = "logistic";
    const formData = new FormData();
    formData.append("file", file);
    return assetsServices.createUploadFile(formData, module);
  };

  const findById = (id: string): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentServices.getStockAdjustmentById(id);
  };

  const createDraft = (
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentServices.createStockAdjustment(payload);
  };

  const submit = (
    docId: string | "-",
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentServices.submitStockAdjustment(payload, docId);
  };

  const update = (
    id: string,
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> => {
    return stockAdjustmentServices.updateStockAdjustment(payload, id);
  };

  const buildCreateDto = (): StockAdjustmentRequestCreateDTO => ({
    attachment: "",
    deletedLineIds: [],
    notes: "",
    stockAdjustmentLines: [],
    type: "" as LogisticStockAdjustmentType,
    warehouseLocationId: "",
  });

  const buildLineCreateDto = (): StockAdjustmentLineRequestCreateDTO => ({
    physicalQty: 0,
    productId: "",
    qty: 0,
    id: "",
    uomId: "",
  });

  const mapFormRowToCreateLineDto = (
    row: StockAdjRow
  ): StockAdjustmentLineRequestCreateDTO => {
    const line: StockAdjustmentLineRequestCreateDTO = buildLineCreateDto();

    line.physicalQty = row.physicalQty || 0;
    line.productId = row.productId || "";
    line.qty = row.qty || 0;
    line.id = row.id || "";
    line.uomId = row.uomId || "";

    return line;
  };

  const mapFormToCreateDto = (
    form: FormValue
  ): StockAdjustmentRequestCreateDTO => {
    const req: StockAdjustmentRequestCreateDTO = buildCreateDto();

    req.attachment = form.attachment || "";
    req.deletedLineIds = form.deletedLineIds || [];
    req.notes = form.notes || "";
    req.stockAdjustmentLines =
      form.stockAdjustmentRows.map<StockAdjustmentLineRequestCreateDTO>(
        mapFormRowToCreateLineDto
      );
    req.type = form.type;
    req.warehouseLocationId = form.warehouseLocationId || "";

    return req;
  };

  const mapDetailLineToFormRow = (
    line: StockAdjustmentLineResponseDTO
  ): StockAdjRow => {
    const row: StockAdjRow = initStockAdjRow();

    row.differenceQty = line.differenceQty || 0;
    row.physicalQty = line.physicalQty || 0;
    row.productCode = line.product?.code || "";
    row.productId = line.product?.id || "";
    row.productMerk = line.product?.merk || "";
    row.productName = line.product?.name || "";
    row.qty = line.qty || 0;
    row.id = line.id;
    row.uomId = line.uom?.id || "";
    row.uomName = line.uom?.name || "";

    return row;
  };

  const mapDetailToForm = (detail: StockAdjustmentResponseDTO): FormValue => {
    const form: FormValue = initFormValue();

    form.attachment = detail.attachment;
    form.brand = ""; // tidak di provide
    form.date = detail.date ? moment(detail.date) : null;
    form.deletedLineIds = [];
    form.documentNumber = detail.documentNumber;
    form.notes = detail.notes;
    form.productCategoryId = ""; // tidak di provide
    form.state = detail.state;
    form.stockAdjustmentRows = detail.stockAdjustmentLines.map<StockAdjRow>(
      mapDetailLineToFormRow
    );
    form.type = detail.type;
    form.warehouseLocation = detail.warehouseLocation;
    form.warehouseLocationId = detail.warehouseLocationId;

    return form;
  };

  const buildDetailDto = (): StockAdjustmentResponseDTO => ({
    attachment: "",
    branchId: "",
    branchName: "",
    createdDate: "",
    date: "",
    documentNumber: "",
    id: "",
    journalNumber: "",
    modifiedDate: "",
    notes: "",
    postingDate: "",
    state: "" as LogisticStockAdjustmentState,
    stockAdjustmentLines: [],
    submittedDate: "",
    type: "" as LogisticStockAdjustmentType,
    warehouseLocation: "",
    warehouseLocationId: "",
  });

  const viewAttachment = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
    link.remove();
  };

  const isRowsInvalid = (rows: Array<StockAdjRow>): boolean => {
    const isEmpty: boolean = rows.length === 0;
    const emptyProduct = !!rows.find(row => !row.productId);

    return isEmpty || emptyProduct;
  };

  return {
    isRowsInvalid,
    buildCreateDto,
    buildDetailDto,
    buildLineCreateDto,
    createDraft,
    findById,
    mapDetailLineToFormRow,
    mapDetailToForm,
    mapFormRowToCreateLineDto,
    mapFormToCreateDto,
    update,
    uploadAttachment,
    submit,
    viewAttachment,
  };
};
