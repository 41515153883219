import vue from "vue";
const GeneralDetails = () =>
  import(/* webpackPrefetch: true */ "./GeneralDetails.vue");
const AddressDetails = () =>
  import(/* webpackPrefetch: true */ "./AddressDetails.vue");
const BankDetails = () =>
  import(/* webpackPrefetch: true */ "./BankDetails.vue");

const EmployeeDetails = () =>
  import(/* webpackPrefetch: true */ "./EmployeeDetails.vue");
const CustomerDetails = () =>
  import(/* webpackPrefetch: true */ "./CustomerDetails.vue");
const SupplierDetails = () =>
  import(/* webpackPrefetch: true */ "./SupplierDetails.vue");

vue.component("GeneralDetails", GeneralDetails);
vue.component("AddressDetails", AddressDetails);
vue.component("BankDetails", BankDetails);

vue.component("EmployeeDetails", EmployeeDetails);
vue.component("CustomerDetails", CustomerDetails);
vue.component("SupplierDetails", SupplierDetails);
