import { RouteConfig } from "vue-router";

// Models
import { companyLoader } from "@/loader";
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.SETTINGS_ROUTE.path,
  name: config.SETTINGS_ROUTE.name,
  meta: config.SETTINGS_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.COA_INDEX_ROUTE.path,
      name: config.COA_INDEX_ROUTE.name,
      meta: config.COA_INDEX_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./chart-of-account/IndexPage.vue"),
    },
    {
      path: config.COMPANY_ROUTE.path,
      name: config.COMPANY_ROUTE.name,
      meta: config.COMPANY_ROUTE.meta,
      beforeEnter: companyLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./company/IndexPage.vue"),
    },
    {
      path: config.CURRENCY_ROUTE.path,
      name: config.CURRENCY_ROUTE.name,
      meta: config.CURRENCY_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Currency.vue"),
    },
    {
      path: config.CALENDAR_ROUTE.path,
      name: config.CALENDAR_ROUTE.name,
      meta: config.CALENDAR_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Calendar.vue"),
    },
    {
      path: config.UOM_ROUTE.path,
      name: config.UOM_ROUTE.name,
      meta: config.UOM_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./UOM.vue"),
    },
    {
      path: config.BANK_ROUTE.path,
      name: config.BANK_ROUTE.name,
      meta: config.BANK_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Bank.vue"),
    },
    {
      path: config.TAX_ROUTE.path,
      name: config.TAX_ROUTE.name,
      meta: config.TAX_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Tax.vue"),
    },

    {
      path: config.TAX_INVOICE_SERIAL_NUMBER.path,
      name: config.TAX_INVOICE_SERIAL_NUMBER.name,
      meta: config.TAX_INVOICE_SERIAL_NUMBER.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./taxinvoiceserialnumber/TaxInvoiceSerialNumber.vue"
        ),
    },
    {
      path: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.path,
      name: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.name,
      meta: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./taxinvoiceserialnumber/DetailTaxInvoiceSerialNumber.vue"
        ),
    },
    {
      path: config.TAX_INVOICE_CODE.path,
      name: config.TAX_INVOICE_CODE.name,
      meta: config.TAX_INVOICE_CODE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./TaxInvoiceCode.vue"),
    },
    {
      path: config.PREFERENCES_ROUTE.path,
      name: config.PREFERENCES_ROUTE.name,
      meta: config.PREFERENCES_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./preferences/Preferences.vue"),
    },
  ],
};

export default router;
