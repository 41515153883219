import { SearchBuilder } from "@/builder";
import { ResponsePreference } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import store from "@/store";

const usePreferences = () => {
  const findAll = () => {
    return settingsServices.getPreference({});
  };

  const findBaseCurrency = (): Promise<ResponsePreference[]> => {
    const q = "search=key~*feature_base_currency*";
    return settingsServices.getPreference({ name: q });
  };

  const findBaseAccountContact = (): Promise<ResponsePreference[]> => {
    const builder = new SearchBuilder();
    const search = builder
      .push(["key", "account_setup_account_receiviables"], { like: "both" })
      .or()
      .push(["key", "account_setup_sales_revenue"], { like: "both" })
      .or()
      .push(["key", "account_setup_account_payables"], { like: "both" })
      .or()
      .push(["key", "account_setup_prepayment_ap"], { like: "both" })
      .build();
    const q = "search=" + search;
    return settingsServices.getPreference({ name: q });
  };

  const findByKey = (key: string): ResponsePreference | undefined => {
    const val: ResponsePreference | undefined =
      store.getters["preferenceStore/GET_PREFERENCE_BY_KEY"](key);
    return val;
  };

  return { findBaseCurrency, findBaseAccountContact, findAll, findByKey };
};

export default usePreferences;
