import { SearchBuilder } from "@/builder";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  CreateCompanyDto,
  ListCompanyDto,
  UpdateCompanyDto,
} from "@/models/interface/company";
import { companyService } from "@/services/company.service";
import { FormValue } from "@/store/company.store";
import { initForm } from "@/store/resources/Company.resource";
import {
  formatTaxNumbertoNumber,
  formatTfn,
} from "@/validator/globalvalidator";

export type SearchField = Partial<{
  code: string;
  name: string;
  address: string;
  taxNumber: string;
  phone: string;
  fax: string;
  website: string;
  email: string;
}>;

const useCompany = () => {
  const findAll = (
    params?: RequestQueryParams
  ): Promise<Pagination<ListCompanyDto>> => {
    return companyService.getList(params);
  };

  const create = (payload: CreateCompanyDto): Promise<boolean> => {
    return companyService.create(payload);
  };

  const update = (
    companyId: string,
    payload: UpdateCompanyDto
  ): Promise<boolean> => {
    return companyService.update(companyId, payload);
  };

  const initUpdateDto = (): UpdateCompanyDto => ({
    address: "",
    code: "",
    email: "",
    fax: "",
    logo: "",
    name: "",
    phone: "",
    taxNumber: "",
    website: "",
  });

  const initListCompanyDto = (): ListCompanyDto => ({
    address: "",
    code: "",
    email: "",
    fax: "",
    logo: "",
    name: "",
    phone: "",
    taxNumber: "",
    website: "",
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
  });

  const initPageListCompanyDto = (): Pagination<ListCompanyDto> => ({
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  });

  const initCreateDto = (): CreateCompanyDto => ({
    address: "",
    code: "",
    email: "",
    fax: "",
    logo: "",
    name: "",
    phone: "",
    taxNumber: "",
    website: "",
  });

  const mapFormToCreateDto = (field: FormValue): CreateCompanyDto => {
    const req: FormValue = initCreateDto();

    req.address = field.address;
    req.code = field.code;
    req.email = field.email;
    req.fax = field.fax;
    req.logo = field.logo;
    req.name = field.name;
    req.phone = field.phone;
    req.taxNumber = field.taxNumber;
    req.website = field.website;

    return req;
  };

  const mapFormToUpdateDto = (field: FormValue): UpdateCompanyDto => {
    const req: FormValue = initUpdateDto();

    req.address = field.address;
    req.code = field.code;
    req.email = field.email;
    req.fax = field.fax;
    req.logo = field.logo;
    req.name = field.name;
    req.phone = field.phone;
    req.taxNumber = field.taxNumber;
    req.website = field.website;

    return req;
  };

  const mapOneToForm = (data: ListCompanyDto): FormValue => {
    const form = initForm();

    form.address = data.address || "";
    form.code = data.code || "";
    form.email = data.email || "";
    form.fax = data.fax || "";
    form.logo = data.logo || "";
    form.name = data.name || "";
    form.phone = data.phone || "";
    form.taxNumber = data.taxNumber || "";
    form.website = data.website || "";

    return form;
  };

  const searchBy = ({
    code = "",
    address = "",
    email = "",
    fax = "",
    name = "",
    phone = "",
    taxNumber = "",
    website = "",
  }: SearchField): string => {
    const query: Array<string> = [];
    const builder = new SearchBuilder();

    //#region search
    if (code) {
      query.push(
        builder
          .push(["code", code], {
            like: "both",
          })
          .build()
      );
    }

    if (address) {
      query.push(
        builder
          .push(["address", address], {
            like: "both",
          })
          .build()
      );
    }

    if (email) {
      query.push(
        builder
          .push(["email", email], {
            like: "both",
          })
          .build()
      );
    }

    if (fax) {
      query.push(
        builder
          .push(["fax", fax], {
            like: "both",
          })
          .build()
      );
    }

    if (name) {
      query.push(
        builder
          .push(["name", name], {
            like: "both",
          })
          .build()
      );
    }
    if (phone) {
      query.push(
        builder
          .push(["phone", phone], {
            like: "both",
          })
          .build()
      );
    }
    if (taxNumber) {
      query.push(
        builder
          .push(["taxNumber", taxNumber], {
            like: "both",
          })
          .build()
      );
    }
    if (website) {
      query.push(
        builder
          .push(["website", website], {
            like: "both",
          })
          .build()
      );
    }
    //#endregion

    return query.join(builder.OR);
  };

  const toTaxFormat = (tax: string): string => {
    return formatTfn(tax);
  };

  const toPlainTax = (tax: string): string => {
    return formatTaxNumbertoNumber(tax);
  };

  return {
    create,
    findAll,
    initCreateDto,
    initListCompanyDto,
    initPageListCompanyDto,
    initUpdateDto,
    mapFormToCreateDto,
    mapFormToUpdateDto,
    searchBy,
    update,
    mapOneToForm,
    toTaxFormat,
    toPlainTax,
  };
};

export default useCompany;
