import moment, { Moment } from "moment";

export const useDisabledFromTomorrow = (current: Moment): boolean => {
  return current > moment().endOf("day");
};

export const useDisabledBackDate = (current: Moment): boolean => {
  return current < moment().subtract(1, "days").endOf("day");
};

export const useMaxOneYear = (
  current: Moment,
  maxDate: Moment | null
): boolean => {
  if (!maxDate) return false;
  return current.isAfter(maxDate);
};

export const useMinByDate = (
  current: Moment,
  minDate: Moment | null
): boolean => {
  if (!minDate) return false;
  return current.isBefore(minDate);
};
