/**
 * @deprecated
 * use {@linkcode constant/enums/storage.enum}
 */
export enum StorageKeys {
  CATEGORY = "app_category",
  COUNTRY = "app_country",
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
}
