import { RoutePathModel } from "../interface/common.interface";
export const UPLOADER_ROUTE: RoutePathModel = {
  path: "uploader",
  url: "/uploader",
  name: "lbl_uploader",
  meta: {
    breadcrumb: "lbl_uploader",
    title_head: "lbl_uploader",
    need_auth: true,
  },
};

//COA
export const UPLOADER_INTERNAL_CONTRACT: RoutePathModel = {
  path: "/uploader/internal-contract",
  url: "/uploader/internal-contract",
  name: "lbl_uploader_internal_contract",
  meta: {
    breadcrumb: [
      {
        name: "lbl_uploader",
        icon: "upload",
      },
      {
        name: "lbl_uploader_internal_contract",
        href: "/uploader/internal-contract",
      },
    ],
    title_head: "lbl_uploader_internal_contract",
  },
};
