import vue from "vue";
const CustomerSelect = () =>
  import(/* webpackPrefetch: true */ "./CustomerSelect.vue");
const CurrencySelect = () =>
  import(/* webpackPrefetch: true */ "./CurrencySelect.vue");
const BranchSelect = () =>
  import(/* webpackPrefetch: true */ "./BranchSelect.vue");
const MerkSelect = () => import(/* webpackPrefetch: true */ "./MerkSelect.vue");
const ProductSelect = () =>
  import(/* webpackPrefetch: true */ "./ProductSelect.vue");
const ExpenseAccountSelect = () =>
  import(/* webpackPrefetch: true */ "./ExpenseAccountSelect.vue");
const TaxCodeSelect = () =>
  import(/* webpackPrefetch: true */ "./TaxCodeSelect.vue");
const MasterProductMerkSelect = () =>
  import(/* webpackPrefetch: true */ "./MasterProductMerkSelect.vue");
const CoaSelect = () => import(/* webpackPrefetch: true */ "./CoaSelect.vue");
const RoleSelect = () => import(/* webpackPrefetch: true */ "./RoleSelect.vue");
const MenuSelect = () => import(/* webpackPrefetch: true */ "./MenuSelect.vue");
const ContactSelect = () =>
  import(/* webpackPrefetch: true */ "./ContactSelect.vue");
const LocationSelect = () =>
  import(/* webpackPrefetch: true */ "./LocationSelect.vue");

const SelectSupplierType = () =>
  import(/* webpackPrefetch: true */ "./SelectSupplierType.vue");

vue.component("customer-select", CustomerSelect);
vue.component("currency-select", CurrencySelect);
vue.component("branch-select", BranchSelect);
vue.component("merk-select", MerkSelect);
vue.component("product-select", ProductSelect);
vue.component("expense-account-select", ExpenseAccountSelect);
vue.component("tax-code-select", TaxCodeSelect);

// TODO: remove global component registration
vue.component("master-product-merk-select", MasterProductMerkSelect);

vue.component("coa-select", CoaSelect);
vue.component("contact-select", ContactSelect);
vue.component("role-select", RoleSelect);
vue.component("menu-select", MenuSelect);

vue.component("location-select", LocationSelect);
vue.component("supplier-type-select", SelectSupplierType);
