import {
  Row,
  useDeleteRows,
  useProduct,
  usePurchaseOrder,
  useReceiveItem,
} from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import {
  ReceivingItemAssetRequestDto,
  ReceivingItemCreateRequestDto,
  ReceivingItemDraftResponseDto,
  ReceivingItemLineDraftResponseDto,
  ReceivingItemLineRequestDto,
} from "@/models/interface/receive-item";
import {
  initDetailGrDraft,
  initFormLine,
  initFormValue,
} from "@/store/resources/GoodsReceiptChecklist.resource";
import { Moment } from "moment";

/**
 * @description
 * if generated from PO then `source` = po
 * if generated from detail GR then `source` = detail
 * if generated from "add row" then `source` = manual
 */
export type RowSource = "po" | "detail" | "manual";
type ReceiveItemLine = ReceivingItemLineRequestDto &
  Pick<
    ReceivingItemLineDraftResponseDto,
    "locationReceived" | "productCode" | "productName" | "productUom"
  > & { source: RowSource };
export type ReceivingItemRow = Row<ReceiveItemLine, number>;
type FormWithLines = Omit<
  ReceivingItemCreateRequestDto,
  "receiveItems" | "receiveDate"
> & {
  receiveItems: Array<ReceivingItemRow>;
  receiveDate: Moment | null;
  deletedReceiveItemIds?: Array<string>;
};
type FormDetail = Pick<
  ReceivingItemDraftResponseDto,
  | "branchWarehouse"
  | "supplierName"
  | "purchaseOrderNumber"
  | "checklistNumber"
  | "createdBy"
>;
export type FormValue = FormWithLines & FormDetail;
export type ModalState = {
  visible: boolean;
  data: Array<ReceivingItemAssetRequestDto>;
  toggle: () => void;
};
export type State = {
  form: FormValue;

  // variable untuk filter additional product berdasarkan po type
  poProductType: string;

  modalAsset: ModalState;
  detailGrDraft: ReceivingItemDraftResponseDto;
};

const state: State = {
  form: initFormValue(),
  poProductType: "",
  modalAsset: {
    visible: false,
    data: [],
    toggle() {
      this.visible = !this.visible;
    },
  },
  detailGrDraft: initDetailGrDraft(),
};

const getters = {
  getForm: (st: State): FormValue => {
    return st.form;
  },
  getPoProductType: (st: State): string => {
    return st.poProductType;
  },
  getModalAssetState: (st: State): ModalState => {
    return st.modalAsset;
  },
  getDetailGrDraft: (st: State): ReceivingItemDraftResponseDto => {
    return st.detailGrDraft;
  },
  isStatusDraft: (st: State): boolean => {
    return st.detailGrDraft.status === LogisticReceivingItemStateEnum.DRAFT;
  },
  isStatusWaitApproval: (st: State): boolean => {
    return (
      st.detailGrDraft.status ===
      LogisticReceivingItemStateEnum.WAIT_FOR_APPROVAL
    );
  },
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy: FormValue = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setPoProductType: (st: State, payload: string): void => {
    st.poProductType = payload;
  },
  setDetailGrDraft: (
    st: State,
    payload: ReceivingItemDraftResponseDto
  ): void => {
    st.detailGrDraft = payload;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    commit("setForm", initFormValue());
    commit("setPoProductType", "");
    commit("setDetailGrDraft", initDetailGrDraft());
  },
  autofillFromPo: (context, poId: string): void => {
    const { commit, dispatch } = context;
    const { findById } = usePurchaseOrder();
    findById(poId).then(res => {
      const form: Partial<FormValue> = {
        supplierName: res.supplierName || "",
        supplierId: res.supplierId || "",
        supplierBillToAddress: res.supplierBillToAddress || "",
        currencyCode: res.currency || "",
        currencyRate: res.currencyRate || ONE,
        branchWarehouse: res.branchWarehouseName || "",
        branchWarehouseId: res.branchWarehouseId || "",
        description: res.description || "",
        taxType: res.taxType,
        receiveItems:
          res.purchaseOrderLines
            ?.filter(item => item.qtyOutstanding > 0)
            .map((item, i) => {
              const row: ReceivingItemRow = initFormLine();
              const [partNumber] = item.partNumbers;

              row.purchaseOrderLineId = item.id || "";
              row.productId = item.productId || "";
              row.productUomId = item.uomId || "";
              row.partNumber = partNumber || "";
              row.qtyPO = item.qtyOutstanding || 0;
              row.price = item.price || 0;
              row.taxCodeId = item.taxCodeId || "";
              row.merk = item.merk || "";
              row.discountValue = item.discountValue || 0;
              row.itemAssets = item.itemAssets;
              row.productCode = item.productCode || "";
              row.productName = item.productName || "";
              row.productUom = item.uom || "";
              row.key = i;
              row.source = "po";

              return row;
            }) || [],
      };

      commit("setPoProductType", res.productType);
      commit("setForm", form);
      dispatch("setDefaultLocationReceiveLine");
    });
  },
  /**
   * @description
   * set default location receive per product
   * from product detail
   */
  setDefaultLocationReceiveLine: (context): void => {
    const { state } = context;
    const { findById } = useProduct();
    const local: State = state;
    Promise.all(
      local.form.receiveItems.map<Promise<ReceivingItemRow>>(async item => {
        const { locationReceiveId = "", locationReceiveName = "" } =
          await findById(item.productId);

        item.locationReceivedId = locationReceiveId || "";
        item.locationReceived = locationReceiveName || "";

        return item;
      })
    );
  },
  addReceiveItemLine: (context): void => {
    const { state } = context;
    const local: State = state;
    const row: ReceivingItemRow = initFormLine();

    row.source = "manual";

    local.form.receiveItems.push(row);
  },
  removeReceiveItemLine: (context, rowKeys: Array<number>): void => {
    const { state, commit } = context;
    const local: State = state;
    const { newSource, deletedRows } = useDeleteRows(
      local.form.receiveItems,
      rowKeys
    );
    const form: Partial<FormValue> = {
      receiveItems: newSource,
      deletedReceiveItemIds: deletedRows
        .filter(item => !!item.id)
        .map<string>(item => item.id),
    };
    commit("setForm", form);
  },
  toggleModalAsset: (
    context,
    payload: Array<ReceivingItemAssetRequestDto>
  ): void => {
    const { state } = context;
    const local: State = state;
    local.modalAsset.data = payload;
    local.modalAsset.toggle();
  },
  mapDetailDraftToForm: (
    context,
    payload: ReceivingItemDraftResponseDto
  ): void => {
    const { commit } = context;
    const { mapDetailDraftToForm: mapToForm } = useReceiveItem();
    const form: FormValue = mapToForm(payload);
    commit("setForm", form);
    commit("setDetailGrDraft", payload);

    // FIXME: butuh product type di detail dto nya supaya bisa tambah product
    // berdasarkan product type PO
    // commit("setPoProductType", "");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
