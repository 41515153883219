import { RouteConfig } from "vue-router";

// Models
import { grPriceCreateLoader, grPriceLoader } from "@/loader";
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.PURCHASING_ROUTE.path,
  name: config.PURCHASING_ROUTE.name,
  meta: config.PURCHASING_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.GOODS_RECEIPT_PRICE_LIST_ROUTE.path,
      name: config.GOODS_RECEIPT_PRICE_LIST_ROUTE.name,
      meta: config.GOODS_RECEIPT_PRICE_LIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-price/ListReceivingItems.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_PRICE_CREATE_ROUTE.path,
      name: config.GOODS_RECEIPT_PRICE_CREATE_ROUTE.name,
      meta: config.GOODS_RECEIPT_PRICE_CREATE_ROUTE.meta,
      beforeEnter: grPriceCreateLoader,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-price/FormPage.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_PRICE_EDIT_ROUTE.path,
      name: config.GOODS_RECEIPT_PRICE_EDIT_ROUTE.name,
      meta: config.GOODS_RECEIPT_PRICE_EDIT_ROUTE.meta,
      props: true,
      beforeEnter: grPriceLoader,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-price/FormPage.vue"
        ),
    },
    {
      path: config.GOODS_RECEIPT_PRICE_DETAIL_ROUTE.path,
      name: config.GOODS_RECEIPT_PRICE_DETAIL_ROUTE.name,
      meta: config.GOODS_RECEIPT_PRICE_DETAIL_ROUTE.meta,
      props: true,
      beforeEnter: grPriceLoader,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./goods-receipt-price/DetailPage.vue"
        ),
    },
    {
      path: config.PURCHASINGCLOSEPERIODE_ROUTE.path,
      name: config.PURCHASINGCLOSEPERIODE_ROUTE.name,
      meta: config.PURCHASINGCLOSEPERIODE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./PurchasingClosePeriod.vue"),
    },
    {
      path: config.TRANSACTION_RETURNTOSUPPLIER_ROUTE.path,
      name: config.TRANSACTION_RETURNTOSUPPLIER_ROUTE.name,
      meta: config.TRANSACTION_RETURNTOSUPPLIER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./supplier-return/SupplierReturnListView.vue"
        ),
    },
    {
      path: config.TRANSACTION_CREATERETURNTOSUPPLIER_ROUTE.path,
      name: config.TRANSACTION_CREATERETURNTOSUPPLIER_ROUTE.name,
      meta: config.TRANSACTION_CREATERETURNTOSUPPLIER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./supplier-return/SupplierReturnFormView.vue"
        ),
    },
    {
      path: config.TRANSACTION_EDITRETURNTOSUPPLIER_ROUTE.path,
      name: config.TRANSACTION_EDITRETURNTOSUPPLIER_ROUTE.name,
      meta: config.TRANSACTION_EDITRETURNTOSUPPLIER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./supplier-return/SupplierReturnFormView.vue"
        ),
    },
    {
      path: config.REPORT_PURCHASEORDER_ROUTE.path,
      name: config.REPORT_PURCHASEORDER_ROUTE.name,
      meta: config.REPORT_PURCHASEORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportPurchaseOrder.vue"),
    },
    {
      path: config.REPORT_PURCHASEORDER_DETAIL_ROUTE.path,
      name: config.REPORT_PURCHASEORDER_DETAIL_ROUTE.name,
      meta: config.REPORT_PURCHASEORDER_DETAIL_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/PurchaseOrderDetailReport.vue"
        ),
    },
    {
      path: config.PURCHASEORDER_ROUTE.path,
      name: config.PURCHASEORDER_ROUTE.name,
      meta: config.PURCHASEORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./PurchaseOrder.vue"),
    },
    {
      path: config.CREATEPURCHASEORDER_ROUTE.path,
      name: config.CREATEPURCHASEORDER_ROUTE.name,
      meta: config.CREATEPURCHASEORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreatePurchaseOrder.vue"),
    },
    {
      path: config.VIEWPURCHASEORDER_ROUTE.path,
      name: config.VIEWPURCHASEORDER_ROUTE.name,
      meta: config.VIEWPURCHASEORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./CreatePurchaseOrder.vue"),
    },
    {
      path: config.PURCHASEREQUISITIONLIST_ROUTE.path,
      name: config.PURCHASEREQUISITIONLIST_ROUTE.name,
      meta: config.PURCHASEREQUISITIONLIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./purchase-requisition/ListPurchaseRequisition.vue"
        ),
    },
    {
      path: config.PURCHASEREQUISITIONCREATE_ROUTE.path,
      name: config.PURCHASEREQUISITIONCREATE_ROUTE.name,
      meta: config.PURCHASEREQUISITIONCREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./purchase-requisition/CreatePurchaseRequisition.vue"
        ),
    },
    {
      path: config.PURCHASEREQUISITIONEDIT_ROUTE.path,
      name: config.PURCHASEREQUISITIONEDIT_ROUTE.name,
      meta: config.PURCHASEREQUISITIONEDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./purchase-requisition/CreatePurchaseRequisition.vue"
        ),
    },
  ],
};

export default router;
