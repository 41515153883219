export enum INVOICE_SOURCE {
  RECURING_INVOICE = "Recurring Invoice",
  UNIT_SALE = "Unit Sale",
  PRODUCT_SALE = "Product Sale",
  INVOICE_OTHER = "Invoice Other",
  ASSET_SALE = "Asset Sale",
}

export enum INVOICE_TYPE {
  INVOICE_AP = "Invoice AP",
  INVOICE_PREPAYMENT = "Prepayment",
  INVOICE_DEBIT_MEMO = "Debit Memo",
}

export enum InvoiceArTypeEnum {
  INVOICE_AR = "INVOICE_AR",
  CREDIT_MEMO = "CREDIT_MEMO",
  PREPAYMENT = "PREPAYMENT",
}
