import { ValidationTableType } from "@/models/constant/interface/common.interface";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";

export const trimSpaceToUnderscore = (value: string): string => {
  return value.toUpperCase().replace(/\s/gi, "_");
};

/**
 * set numbering for data pagination
 * @param page current page pagination
 * @param limit limit pagination
 * @param index index row
 * @returns numbering
 */
export const setNumbering = (
  page: number,
  limit: number,
  index: number
): number => {
  return (page ?? 0) * (limit ?? 10) + (index + 1);
};

export const findPercentage = (amount: number, total: number): number => {
  return new Decimal(amount).dividedBy(total).times(100).toNumber();
};

export function validateTable<T>(
  dataSource: T[],
  columns: string[]
): [boolean, { [prop: string]: ValidationTableType<T> }] {
  let invalid = false;
  const key: { [prop: string]: ValidationTableType<T> } = {};

  dataSource.forEach((row, index) => {
    columns.forEach(col => {
      // set generic rules -> falsy
      if (!row[col]) {
        key[col] = {
          message: `invalid value ${col} at row ${index + 1}`,
          rowAt: index + 1,
          value: row[col],
        };
        invalid = true;
      }
      if (invalid) return;
    });
    if (invalid) return;
  });

  return [invalid, key];
}

export const removeDuplicate = <T>(arr: T[]): T[] => {
  return arr.filter(function (item, pos, self) {
    return self.indexOf(item) == pos;
  });
};

export const removeDuplicateObj = <TData = any>(
  arr: Array<TData>,
  key: keyof TData
): TData[] => {
  return arr.filter(
    (dataFilter, index, self) =>
      index ===
      self.findIndex(t => t[key] === dataFilter[key] && dataFilter[key])
  );
};

export const defineFilename = (filename: string): string => {
  const now: Moment = moment();
  return filename + "_(" + now.format(DATE_TIME_HOURS_DEFAULT_FORMAT) + ")";
};

export const isNotUnique = (data: string[]): boolean => {
  let flag = ""; // current data for each iteration
  let found = false; // flag is data iteration is not different
  for (let i = 0; i < data.length; i++) {
    const el = data[i];

    if (flag === "") {
      flag = el;
    } else if (flag.toUpperCase() === el.toUpperCase()) {
      continue;
    } else if (flag.toUpperCase() !== el.toUpperCase()) {
      found = true;
      break;
    }
  }

  return found;
};

export const displayNeg = (value: number): number => {
  return value < 0 ? 0 : value;
};

export const toTitlecase = (str: string): string => {
  if (!str) return "";

  return str
    .toLowerCase()
    .split(" ")
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
