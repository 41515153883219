import { Row, useDeleteRows, useProduct } from "@/hooks";
import { ProductTypeEnum } from "@/models/enums/ProductType.enum";
import {
  ProductCreateRequestDto,
  ProductDetailDto,
  ProductSupplierDto,
  ProductUomConversionDto,
} from "@/models/interface/master-product";
import {
  ProductAccountSelectType,
  initFormProduct,
  initProductDetailDto,
} from "./resources/product.resource";

type FormDetail = Pick<
  ProductDetailDto,
  "categoryName" | "locationReceiveName"
>;
export type ProductSupplierRow = Row<ProductSupplierDto, number>;
export type UomConversionRow = Row<ProductUomConversionDto, number>;
type FormWithoutList = Omit<
  ProductCreateRequestDto & FormDetail,
  | "productSuppliers"
  | "uomConversionDTOs"
  | "productAccount"
  | "assetCostAccountId"
  | "assetClearingAccountId"
  | "assetDepreciationAccountId"
  | "assetAccumulationAccountId"
>;
export type FormValue = FormWithoutList & {
  productSuppliers: Array<ProductSupplierRow>;
  uomConversions: Array<UomConversionRow>;
  deleteUomConversions?: Array<string>; // deleted secure ids
  productAccount: ProductAccountSelectType;
};
export type State = {
  form: FormValue;
  detailProduct: ProductDetailDto;
};

const state: State = {
  form: initFormProduct(),
  detailProduct: initProductDetailDto(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy: FormValue = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetailProduct: (st: State, payload: ProductDetailDto): void => {
    st.detailProduct = payload;
  },
};

const getters = {
  getForm: (st: State): FormValue => {
    return st.form;
  },
  isTypeStockable: (st: State): boolean => {
    return (
      (st.form.type?.toUpperCase() || "") ===
      ProductTypeEnum.STOCKABLE.toUpperCase()
    );
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    commit("setForm", initFormProduct());
    commit("setDetailProduct", initProductDetailDto());
  },
  addProductSupplier: (context): void => {
    const { state } = context;
    const { initProductSupplierRow } = useProduct();
    const local: State = state;
    const newRow: ProductSupplierRow = initProductSupplierRow();
    local.form.productSuppliers.push(newRow);
  },
  deleteProductSupplier: (context, payload: Array<number>): void => {
    const { state, commit } = context;
    const local: State = state;
    const { newSource } = useDeleteRows(local.form.productSuppliers, payload);
    commit("setForm", {
      productSuppliers: newSource,
    });
  },
  addUomConversion: (context): void => {
    const { state } = context;
    const { initUomConversionRow } = useProduct();
    const local: State = state;
    const row: UomConversionRow = initUomConversionRow();
    local.form.uomConversions.push(row);
  },
  deleteUomConversion: (context, payload: Array<number>): void => {
    const { state, commit } = context;
    const local: State = state;
    const { deletedRows, newSource } = useDeleteRows(
      local.form.uomConversions,
      payload
    );
    commit("setForm", {
      uomConversions: newSource,
      deleteUomConversions: deletedRows
        .filter(item => !!item.id)
        .map<string>(item => item.id),
    });
  },
  getDetailProduct: (context, productId: string): void => {
    const { commit } = context;
    const { findById, mapProductDetailToForm } = useProduct();
    findById(productId).then(res => {
      const form: FormValue = mapProductDetailToForm(res);
      commit("setForm", form);
      commit("setDetailProduct", res);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
