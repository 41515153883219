import { useContactData } from "@/hooks";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { ResponseContactData } from "@/models/interface/contact.interface";
import { ResponsePreference } from "@/models/interface/settings.interface";

export type MetaField = {
  label: string;
  value: string;
};
export type PreferenceField = {
  invoiceArAssignee: MetaField;
};
export type StorageStatePreference = {
  dataPreference: ResponsePreference[];
  baseCurrency: string;
  baseARReceivableAccount: string;
  baseDecimalPlace: number;
  field: PreferenceField;
};

const state: StorageStatePreference = {
  dataPreference: [] as ResponsePreference[],
  baseCurrency: "",
  baseARReceivableAccount: "",
  baseDecimalPlace: DECIMAL_PLACES_CURRENCY,
  field: {
    invoiceArAssignee: { label: "", value: "" },
  },
};

const mutations = {
  [Mutations.SET_PREFERENCE]: (
    stateVuex: StorageStatePreference,
    payload: ResponsePreference[]
  ): void => {
    stateVuex.dataPreference = payload;
  },
  ["SET_BASE_CURRENCY"]: (
    store: StorageStatePreference,
    payload: string
  ): void => {
    store.baseCurrency = payload;
  },
  ["SET_BASE_AR_RECEIVABLE_ACCOUNT"]: (
    store: StorageStatePreference,
    payload: string
  ): void => {
    store.baseARReceivableAccount = payload;
  },
  ["SET_BASE_DECIMAL_PLACES"]: (
    store: StorageStatePreference,
    payload: number
  ): void => {
    store.baseDecimalPlace = payload;
  },
  mutatePreferenceField: (
    store: StorageStatePreference,
    payload: PreferenceField
  ) => {
    store.field = payload;
  },
};

const getters = {
  getDataPreference: (stateVuex: StorageStatePreference) => {
    return stateVuex.dataPreference;
  },
  ["GET_PREFERENCE_BY_KEY"]:
    (store: StorageStatePreference) =>
    (key: string): ResponsePreference | undefined => {
      return store.dataPreference.find(x => x.key === key);
    },
  getFeatSalesTaxRate: (
    state: StorageStatePreference,
    getters
  ): ResponsePreference | undefined => {
    return getters["GET_PREFERENCE_BY_KEY"]("feature_sales_tax_rate");
  },
};

const actions = {
  dispatchAssigneeField: async (
    context,
    contactId: string
  ): Promise<ResponseContactData | undefined> => {
    if (!contactId) return;
    const { findById } = useContactData();

    const res = await findById(contactId);
    const data: MetaField = {
      label: `${res.firstName} ${res.lastName || ""} - ${
        res.employeeData.position || ""
      }`,
      value: res.id,
    };

    const payload: PreferenceField = {
      ...context.state.field,
      invoiceArAssignee: data,
    };
    context.commit("mutatePreferenceField", payload);
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
