// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { Pagination } from "@/models/constant/interface/common.interface";
import { ListContactDataDTO } from "@/models/interface/ContactDataDto.interface";
import { DetailContactDataDto } from "@/models/interface/contact-data";
import {
  CreateContactData,
  ResponseContactData,
} from "@/models/interface/contact.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";

export class ContactServices extends HttpClient {
  public constructor() {
    super();

    this.listRegionCode = this.listRegionCode.bind(this);
    this.listRegionCity = this.listRegionCity.bind(this);
    this.listContactData = this.listContactData.bind(this);
    this.createContactData = this.createContactData.bind(this);
    this.getContactData = this.getContactData.bind(this);
  }

  public listRegionCode(params: RequestQueryParamsModel): Promise<string[]> {
    return this.get<string[]>(Api.Region + "/code", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listRegionCity(params: RequestQueryParamsModel): Promise<string[]> {
    return this.get<string[]>(Api.Region + "/city", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportContact = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.ContactData + `/report`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * new service {@linkcode getListContactData}
   */
  public listContactData(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDTO>> {
    return this.get<Pagination<ListContactDataDTO>>(Api.ContactData, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListContactData(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDTO>> {
    return this.get<Pagination<ListContactDataDTO>>(Api.ContactData, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createContactData(payload: CreateContactData): Promise<boolean> {
    return this.post<boolean, CreateContactData>(Api.ContactData, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateContactData(
    payload: CreateContactData,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, CreateContactData>(
      Api.ContactData + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getContactDetail}
   */
  public getContactData(id: string): Promise<ResponseContactData> {
    return this.get<ResponseContactData>(Api.ContactData + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getContactDetail(id: string): Promise<DetailContactDataDto> {
    return this.get<DetailContactDataDto>(Api.ContactData + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const contactServices = new ContactServices();
