import { SortDirection } from "@/types";
type SortParam<TData extends Record<string, keyof TData>> = {
  data: Array<TData>;
  field: keyof TData;
  dir: SortDirection;
};

const useSort = () => {
  const sortStr = (
    a: string,
    b: string,
    dir: SortDirection = "ascend"
  ): number => {
    const fieldA = a?.toUpperCase() || "";
    const fieldB = b?.toUpperCase() || "";

    if (fieldA > fieldB) return dir === "ascend" ? 1 : -1;
    if (fieldA < fieldB) return dir === "ascend" ? -1 : 1;
    return 0;
  };

  const sortNumber = (a: number, b: number, dir: SortDirection): number => {
    return dir === "ascend" ? a - b : b - a;
  };

  return { sortStr, sortNumber };
};

export default useSort;
