











import en_US from "ant-design-vue/lib/locale-provider/en_US";
import id_ID from "ant-design-vue/lib/locale-provider/id_ID";
import * as package_ from "../package.json";
import localStorageService from "./services/LocalStorage.service";
import Vue from "vue";
import { LottieLoader } from "@/components/Lottie";

export default Vue.extend({
  name: "App",
  components: {
    LottieLoader,
    vueHeadful: () => import(/* webpackPrefetch: true */ "vue-headful"),
  },
  data: () => ({
    title: "",
    language: en_US,
  }),
  watch: {
    $route(route): void {
      this.title = `${this.$t(route.meta.title_head)} | ${
        process.env.VUE_APP_TITLE_PAGE
      }`;
    },
  },
  created() {
    this.loadLocale();
  },
  mounted() {
    console.log(
      `Web Application Version ${process.env.NODE_ENV} ${package_.version}`
    );
  },
  methods: {
    loadLocale() {
      const lang = localStorageService.getCurrentLang();
      if (lang === "id") {
        this.language = id_ID;
      } else {
        this.language = en_US;
      }
    },
  },
});
