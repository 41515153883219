// /api/v1/master/type

// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ResponseListMaster } from "@/models/interface/contact.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  NewResponseBank,
  RequestCompanyBank,
  RequestMasterBank,
  ResponseCompanyBank,
} from "@/models/interface/master.interface";

export class MasterServices extends HttpClient {
  public constructor() {
    super();

    this.listMaster = this.listMaster.bind(this);
    this.listMasterBank = this.listMasterBank.bind(this);
  }
  public salesChart(params: RequestQueryParamsModel): Promise<any> {
    return this.get<any[]>(Api.SalesChart, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public listMaster(
    params: RequestQueryParamsModel
  ): Promise<ResponseListMaster[]> {
    return this.get<ResponseListMaster[]>(Api.MasterType, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listMasterBank(
    params: RequestQueryParamsModel
  ): Promise<NewResponseBank> {
    return this.get<NewResponseBank>(Api.Bank, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createMasterBank(payload: RequestMasterBank): Promise<boolean> {
    return this.post<boolean, RequestMasterBank>(Api.Bank, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateMasterBank(
    payload: RequestMasterBank,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, RequestMasterBank>(Api.Bank + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public deleteMasterBank(id: string): Promise<boolean> {
    return this.delete<boolean>(Api.Bank + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode CompanyBankService.getList}
   */
  public listCompanyBank(
    params: RequestQueryParamsModel
  ): Promise<ResponseCompanyBank> {
    return this.get<ResponseCompanyBank>(Api.BankCompany, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createCompanyBank(payload: RequestCompanyBank): Promise<boolean> {
    return this.post<boolean, RequestCompanyBank>(Api.BankCompany, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateCompanyBank(
    payload: RequestCompanyBank,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, RequestCompanyBank>(
      Api.BankCompany + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const masterServices = new MasterServices();
