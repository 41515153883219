import "./CharacterLength";
import "./Drawer";
import "./custom/button";
import "./custom/input";
import "./custom/modal";
import "./custom/pagination";

import "./custom/qrcode";
import "./custom/select";
import "./custom/table";

// Contact Data Form
import "@/views/contact-data/index";

// Asset Inquiry Open Form
import "@/views/assets/Inquiry/index";

// AP
import "@/views/account-payables/index";
