export enum Mode {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  DETAIL = "detail",
  SUBMIT = "submit",
  VIEW = "view",
  READ = "read",
}

export enum Locales {
  ENGLISH = "en",
  BAHASA = "id",
  LANGUAGE = "lang",
}

/**
 * @deprecated
 * use {@linkcode StorageKeys.DECIMAL_POINT}
 */
export enum APP_DECIMAL_PLACES {
  DP = "dp",
}

export enum UrlQueryKey {
  DIR = "d",
  SORT = "s",
  SEARCH = "q",
  LIMIT = "l",
  PAGE = "p",
}

export enum CurrencyCodeEnum {
  IDR = "IDR",
}
