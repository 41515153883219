// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { Pagination } from "@/models/constant/interface/common.interface";
import { ReportAgingApResponseDto } from "@/models/interface/AgingApReport.interface";
import { ApPaymentReportDto } from "@/models/interface/ApPaymentReport.interface";
import { DetailPoListDTO } from "@/models/interface/IPurchaseOrderReportDetailDTO";
import { InvoiceApSummaryResponseDto } from "@/models/interface/InvoiceApSummary.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  InvoiceAPCreateDTO,
  InvoiceAPResponse,
  InvoiceAPUpdateDTO,
  RequestParamDownloadPOGRInvoiceReport,
  RequestParamListPOGRInvoiceReport,
  ResponseDataInvoiceAp,
  ResponseDataInvoiceApAvailable,
} from "@/models/interface/invoiceAp.interface";

export class InvoiceAPServices extends HttpClient {
  getSummary(id: string): Promise<InvoiceApSummaryResponseDto> {
    return this.get<InvoiceApSummaryResponseDto>(
      Api.InvoiceAp + "/summary/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listApInvoice(
    params: RequestQueryParamsModel
  ): Promise<ResponseDataInvoiceAp> {
    return this.get<ResponseDataInvoiceAp>(Api.InvoiceAp, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  listApInvoiceAvailable(
    params: RequestQueryParamsModel
  ): Promise<ResponseDataInvoiceApAvailable[]> {
    return this.get<ResponseDataInvoiceApAvailable[]>(Api.InvoiceApAvailable, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadApInvoice(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadPOGRInvoiceReport(
    params?: Partial<RequestParamDownloadPOGRInvoiceReport>
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.InvoiceAp + "/report/detail-po/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printApInvoice(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detailApInvoice(id: string): Promise<InvoiceAPResponse> {
    return this.get<InvoiceAPResponse>(Api.InvoiceAp + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detailApInvoiceReturn(id: string): Promise<InvoiceAPResponse> {
    return this.get<InvoiceAPResponse>(
      Api.InvoiceAp + `/${id}?returnToSupplier=true`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createApInvoice(payload: InvoiceAPCreateDTO): Promise<InvoiceAPResponse> {
    return this.post<InvoiceAPResponse, InvoiceAPCreateDTO>(
      Api.InvoiceAp,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateApInvoice(
    id: string,
    payload: InvoiceAPUpdateDTO
  ): Promise<InvoiceAPResponse> {
    return this.put<InvoiceAPResponse, InvoiceAPUpdateDTO>(
      Api.InvoiceAp + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitApInvoice(
    id: string,
    payload: InvoiceAPUpdateDTO
  ): Promise<InvoiceAPResponse> {
    return this.post<InvoiceAPResponse, InvoiceAPUpdateDTO>(
      Api.InvoiceAp + `/submit/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  approveApInvoice(
    id: string,
    payload: InvoiceAPUpdateDTO
  ): Promise<InvoiceAPResponse> {
    return this.post<InvoiceAPResponse, InvoiceAPUpdateDTO>(
      Api.InvoiceAp + `/approve/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelApInvoice(id: string): Promise<InvoiceAPResponse> {
    return this.put<InvoiceAPResponse, null>(Api.InvoiceAp + `/cancel/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  rejectApInvoice(id: string): Promise<InvoiceAPResponse> {
    return this.put<InvoiceAPResponse, null>(Api.InvoiceAp + `/reject/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListPODetailReport(
    params?: Partial<RequestParamListPOGRInvoiceReport>
  ): Promise<Pagination<DetailPoListDTO>> {
    return this.get<Pagination<DetailPoListDTO>>(
      Api.InvoiceAp + "/report/detail-po",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportApPayment(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report-ap-payment/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportApPayment(
    params?: RequestQueryParamsModel
  ): Promise<ApPaymentReportDto[]> {
    return this.get<ApPaymentReportDto[]>(
      Api.InvoiceAp + "/report-ap-payment",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListAgingApReport(
    params?: RequestQueryParamsModel
  ): Promise<ReportAgingApResponseDto> {
    return this.get<ReportAgingApResponseDto>(Api.AgingReportAp, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadAgingApReport(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.AgingReportAp + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const invoiceAPServices = new InvoiceAPServices();
