import * as accounting from "./routes/accounting.contstant";
import * as accountpayables from "./routes/accountpayables.constant";
import * as accountreceivables from "./routes/accountreceivables.constant";
import * as assets from "./routes/assets.constant";
import * as auth from "./routes/auth.constant";
import * as cashmanagement from "./routes/cashmanagement.constant";
import * as contactdata from "./routes/contactdata.constant";
import * as dashboard from "./routes/dashboard.constant";
import * as generaljournal from "./routes/generaljournal.constant";
import * as generalledger from "./routes/generalledger.constant";
import * as inquiryassets from "./routes/inquiryassets.constant";
import * as inventory from "./routes/logistic.constant";
import * as profile from "./routes/profile.constant";
import * as purchasing from "./routes/purchasing.constant";
import * as qr from "./routes/qr.constant";
import * as salesorder from "./routes/salesorder.constant";
import * as settings from "./routes/settings.constant";
import * as uploader from "./routes/uploader.contants";
import * as user from "./routes/user.constant";

// Routes
export const MAIN_ROUTE = {
  path: "/",
  url: "/",
  name: "Main",
  // redirect: "/dashboard",
  redirect: "/auth/signin",
  meta: {
    breadcrumb: "Home",
    title_head: "Home",
  },
};

export const config = {
  MAIN_ROUTE,
  ...auth,
  ...user,
  ...dashboard,
  ...contactdata,
  ...settings,
  ...accountreceivables,
  ...assets,
  ...inquiryassets,
  ...inventory,
  ...salesorder,
  ...qr,
  ...generaljournal,
  ...purchasing,
  ...cashmanagement,
  ...accountpayables,
  ...accounting,
  ...generalledger,
  ...uploader,
  ...profile,
};
