
import { Mutations } from '@/models/constant/enums/mutations.enum'
import { StorageStateContactModel } from '@/models/constant/interface/common.interface'
import { AddressDataList, DataSourceBank, DataEmployee } from '@/models/interface/contact.interface'

const state:StorageStateContactModel = {
  addressDataList: [] as AddressDataList[],
  listBillTo: [] as AddressDataList[],
  listShipTo: [] as AddressDataList[],
  bankDataList: [
    {
      bankName: null,
      bankBranch: null,
      bankAccName: null,
      bankAccNumber: null,
      key: 0
    }
  ] as DataSourceBank[],
  dataEmployee: {
    gender: 'Male' as string,
    dateOfBirth: null as string | null,
    startDate: null as string | null,
  } as DataEmployee,
  valueType: [] as string[]
}

const mutations = {
  [Mutations.SET_VALUE_TYPE]: (stateVuex: StorageStateContactModel, payload: string[]):void => {
    stateVuex.valueType = payload
  },
  [Mutations.SET_DATA_EMPLOYEE]: (stateVuex: StorageStateContactModel, payload: DataEmployee):void => {
    stateVuex.dataEmployee = payload
  },
  [Mutations.SET_BANK_DATA_LIST]: (stateVuex: StorageStateContactModel, payload: DataSourceBank[]):void => {
    stateVuex.bankDataList = payload
  },
  [Mutations.SET_LIST_BILL_TO]: (stateVuex: StorageStateContactModel, payload: AddressDataList[]):void => {
    stateVuex.listBillTo = payload
  },
  [Mutations.SET_LIST_SHIP_TO]: (stateVuex: StorageStateContactModel, payload: AddressDataList[]):void => {
    stateVuex.listShipTo = payload
  },
  [Mutations.SET_ADDRESS_DATA_LIST]: (stateVuex: StorageStateContactModel, payload: AddressDataList[]):void => {
    stateVuex.addressDataList = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations
}