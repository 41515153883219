import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { ReqParamDownloadRecurring } from "@/models/interface/AccountReceivables.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseListArInvoice,
  ParamsPrintInvoiceAr,
  PrintTitleRequestDto,
} from "@/models/interface/invoice.interface";
import { arService } from "@/services/ar.service";
import { invoiceServices } from "@/services/invoice.service";
import { useFindMasterType, useMapMasterTypeToOption } from "./master-type";

const useInvoiceAR = () => {
  const addPrintTitle = (payload: PrintTitleRequestDto) => {
    return invoiceServices.addPrintTitle(payload);
  };

  const print = (id: string, params: ParamsPrintInvoiceAr) => {
    return invoiceServices.printInvoice(id, params);
  };

  const findAllPrintTitle = () => {
    return invoiceServices.getPrintTitle();
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return invoiceServices.getListInvoiceAR(params);
  };

  const findById = (id: string) => {
    return invoiceServices.getDetailInvoiceAR(id);
  };

  const toOptions = (
    datas: DataResponseListArInvoice[]
  ): Option<DataResponseListArInvoice>[] => {
    return datas.map<Option<DataResponseListArInvoice>>(item => ({
      label: item.documentNumber,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const downloadRecurring = (params: ReqParamDownloadRecurring) => {
    return arService.downloadRecurring(params);
  };

  const findMasterStatus = async () => {
    const response = await useFindMasterType("INVOICE_AR_STATUS");
    return useMapMasterTypeToOption(response);
  };

  const findStatusForReport = async () => {
    const res = await findMasterStatus();
    const exclude = ["CANCELLED", "REJECTED", "RETURNED"];
    return res.filter(item => !exclude.includes(item.value.toUpperCase()));
  };

  const findInvoiceArPaymentStatus = async () => {
    const response = await findMasterStatus();
    const status = ["PARTIAL_PAID", "FULLY_PAID", "UNPAID"];
    const filtered = response.filter(item =>
      status.includes(item.value.toUpperCase())
    );
    return filtered;
  };

  const findMasterSource = async () => {
    const response = await useFindMasterType("INVOICE_AR_SOURCE");
    return useMapMasterTypeToOption(response);
  };

  const findMasterStatusPphArReceipt = async () => {
    const response = await useFindMasterType("AR_RECEIPT_PPH_STATUS");
    return response;
  };

  const findAllReceivableMutation = (params?: RequestQueryParamsModel) => {
    return invoiceServices.getListReceivableMutation(params);
  };

  const findAllArReceiptReport = async (params?: RequestQueryParamsModel) => {
    return await invoiceServices.getAllArReceiptReport(params);
  };

  const downloadArReceiptReport = async (params?: RequestQueryParamsModel) => {
    return await invoiceServices.downloadArReceiptReport(params);
  };

  const filterBy = (field: { invoiceNo: string }): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.invoiceNo) {
      q.push(
        builder
          .push(["documentNumber", field.invoiceNo], { like: "both" })
          .build()
      );
    }

    return q.join(builder.OR);
  };

  return {
    print,
    filterBy,
    findById,
    downloadArReceiptReport,
    findMasterStatusPphArReceipt,
    findAllArReceiptReport,
    findAllReceivableMutation,
    findMasterSource,
    findAll,
    toOptions,
    downloadRecurring,
    findMasterStatus,
    findInvoiceArPaymentStatus,
    findAllPrintTitle,
    addPrintTitle,
    findStatusForReport,
  };
};

export default useInvoiceAR;
