export enum PREFERENCE_PURCHASE_KEY {
  IMPORT_COST_INCOME_TAX = "purchase_import_cost_pph_22",
  IMPORT_COST_VAT_TAX = "purchase_import_cost_ppn",
}

export enum PREFERENCE_FEATURE_KEY {
  FEATURE_DECIMAL_POINT = "feature_decimal_point",
  FEATURE_BASE_CURRENCY = "feature_base_currency",
}

export enum PREFERENCE_ACCOUNT_SETUP_KEY {
  ACCOUNT_RECEIVABLE = "account_setup_account_receiviables",
  ACCOUNT_SETUP_UNBILLED_PAYABLES = "account_setup_unbilled_payables",
  ACCOUNT_SETUP_ACCOUNT_PAYABLES = "account_setup_account_payables",
  ACCOUNT_SETUP_PREPAYMENT_AR = "account_setup_prepayment_ar",
}
