import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { InventoryLineResponseDto } from "@interface/inventory";

type SearchField = Partial<{
  productCode: string;
  productName: string;
  productId: string;
  brand: string;
  productCategoryId: string;
  branchId: string;
  warehouseId: string;
  rackId: string;
  rackName: string;
  uomName: string;
  uomId: string;

  /**
   * @description
   * find product where qty available is higher than zero (0)
   */
  isAvailable: boolean;
}>;

export interface FormFilter {
  branchId: string;
  branchName: string;
  warehouseId: string;
  warehouseName: string;
  rackId: string;
  rackName: string;
  partCategoryId: string;
  partCategoryName: string;
  partId: string;
  partName: string;
  partNumber: string;
  brand: string;

  reset: () => void;
}

const useInventory = () => {
  const findAllInventoryLine = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InventoryLineResponseDto>> => {
    return logisticServices.getListInventoryLine(params);
  };

  const download = (params: RequestQueryParamsModel) => {
    return logisticServices.downloadListOfProduct(params);
  };

  const print = (params: RequestQueryParamsModel) => {
    return logisticServices.printListOfProduct(params);
  };

  const toOptions = (
    data: Array<InventoryLineResponseDto>,
    labelOpt: "code" | "name" = "code"
  ): Array<Option<InventoryLineResponseDto>> => {
    return data.map<Option<InventoryLineResponseDto>>(item => ({
      label: labelOpt === "code" ? item.product.code : item.product.name,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const toUomOptions = (
    data: Array<InventoryLineResponseDto>
  ): Array<Option<InventoryLineResponseDto>> => {
    return data.map(item => ({
      key: item.uom.id,
      label: item.uom.name,
      value: item.uom.id,
      meta: item,
    }));
  };

  const toRackOptions = (
    data: Array<InventoryLineResponseDto>
  ): Array<Option<InventoryLineResponseDto>> => {
    return data.map(item => ({
      key: item.warehouseLocationId,
      label: item.warehouseLocationName,
      value: item.warehouseLocationId,
      meta: item,
    }));
  };

  /**
   * @deprecated
   * use {@linkcode buildQuerySearch}
   */
  const searchBy = (field: SearchField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (field.productCode) {
      query.push(
        builder
          .push(["product.code", field.productCode], { like: "both" })
          .build()
      );
    }

    if (field.productName) {
      query.push(
        builder
          .push(["product.name", field.productName], { like: "both" })
          .build()
      );
    }

    return query.join(builder.OR);
  };

  const buildQuerySearch = ({
    productId,
    branchId,
    warehouseId,
    rackId,
    rackName,
    productCategoryId,
    brand,
    uomName,
    uomId,
    isAvailable,
  }: SearchField) => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (productId) {
      query.push(builder.push(["product.secureId", productId]).build());
    }

    if (productCategoryId) {
      query.push(
        builder.push(["product.category.secureId", productCategoryId]).build()
      );
    }

    if (branchId) {
      query.push(
        builder
          .push([
            "warehouseLocation.warehouse.branchWarehouse.secureId",
            branchId,
          ])
          .build()
      );
    }

    if (warehouseId) {
      query.push(
        builder
          .push(["warehouseLocation.warehouse.secureId", warehouseId])
          .build()
      );
    }

    if (rackId) {
      query.push(builder.push(["warehouseLocation.secureId", rackId]).build());
    }

    if (rackName) {
      query.push(
        builder
          .push(["warehouseLocation.name", rackName], { like: "both" })
          .build()
      );
    }

    if (brand) {
      query.push(builder.push(["product.merk", brand]).build());
    }

    if (uomId) {
      query.push(builder.push(["uom.secureId", uomId]).build());
    }

    if (uomName) {
      const findByUomQuery = builder
        .push(["uom.name", uomName], { like: "both" })
        .or()
        .push(["uom.unit", uomName], { like: "both" })
        .build();
      query.unshift(findByUomQuery);
    }

    if (isAvailable) {
      const findByAvailableQuery = builder
        .push(["available", "0"], { ht: true })
        .build();
      query.push(findByAvailableQuery);
    }

    const filter: string = query.join(builder.AND);
    const search: string = query.join(builder.OR);

    return { filter, search };
  };

  return {
    buildQuerySearch,
    download,
    findAllInventoryLine,
    print,
    searchBy,
    toOptions,
    toRackOptions,
    toUomOptions,
  };
};

export default useInventory;
