import { Locales } from "@/models/enums/global.enum";
import LocalStorageService from "@/services/LocalStorage.service";
import Vue from "vue";
import VueI18n from "vue-i18n";

function loadLocalMessages() {
  const locales = require.context(
    "../assets/i18n",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadPersistance(): string {
  return LocalStorageService.getCurrentLang() || Locales.ENGLISH;
}

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: loadPersistance(),
  fallbackLocale: Locales.ENGLISH,
  messages: loadLocalMessages(),
});
